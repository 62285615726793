/* * {
  border: .1px solid red
} */

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  /* white-space:nowrap; */
}

/* stops webkit transitions when window is resizing */
.Resize-transition-stopper * {
  transition: none !important;
}

/* Top bar styling */
.Top-bar {
  display: flex;
  flex-direction: row;
  background-color: #CECCCC;
  justify-content: space-between;
  align-items: center;
  height: 5vmin;
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
  max-width: 100%;
  transition: top 0.3s;
}

.Instagram-logo {
  width: 4vmin;
  height: 4vmin;
  align-items: right;
  padding-right: 1vmin;
  position: relative;
  display: flex;
  opacity: .75; 
  transition: 1000ms linear;
}

.Instagram-wrapper {
  position: relative;
  cursor: pointer;
}

.Instagram-logo:hover {
  filter: invert(1);
  opacity: 1;
  -webkit-transition : -webkit-filter 500ms linear;
}

.Instagram-wrapper:hover div{
  visibility: visible;
}

.Email-logo {
  width: 5vmin;
  height: 5vmin;
  display: flex;
  align-items: right;
  vertical-align: middle;
  position: relative;
  opacity: .75;
  transition: 1000ms linear;
}

.Email-logo:hover {
  filter: invert(1);
  opacity: 1;
  -webkit-transition : -webkit-filter 500ms linear;
}

.Logos {
  display: flex;
  flex-direction: row;
  padding-right: 1%;
  justify-content: center;
  align-items: center;
}

.Address {
  color: #282c34;
  /* color: #243e36; */
  font-family: Merienda;
  font-weight: bolder;
  padding-left: 1%;
  font-size: 2vmin;
  text-decoration: none;
  max-width: 30%;
  transition: 500ms ease;
}

.Address:hover {
  color: white;
  transform: scale(1.05);
  -webkit-transition : color 500ms, transform 500ms;
}

.Phone-number {
  position: fixed;
  color: #282c34;
  /* color: #243e36; */
  font-family: Merienda;
  font-weight: bolder;
  font-size: 2vmin;
  left: 50%;
  transform: translateX(-50%);
  transition: 500ms ease;
  text-decoration: none;
}

.Phone-number a {
  text-decoration: inherit;
  color: inherit;
}

.Phone-number:hover {
  color: white;
  transform: scale(1.05) translateX(-48%);
  -webkit-transition : color 500ms, transform 500ms;
}


/* Navbar styling */
.Navbar {
  display: flex;
  flex-direction: row;
  background-color: #65686e;
  /* background-color: #243e36; */
  justify-content: space-between;
  align-items: center;
  height: 10vmin;
  position: fixed;
  width: 100%;
  top: 5vmin;
  z-index: 2;
  max-width: 100%;
  overflow: hidden;
}

.Business-logo {
  position: relative;
  max-width: 10vmin;
  max-height: 10vmin;
  left: 5vmin;
}

.Quote-button-text {
  text-decoration: none;
  color: black;
  font-family: Merienda;
  font-weight: bolder;
  font-size: 2vmin;
  vertical-align: middle;
}

.Nav-button {
  position: relative;
  right: 5vmin;
  /* background-color: rgb(246, 243, 242); */
  background-color: #f1f7ed;
  height: 8vmin;
  width: 20vmin;
  border-radius: 2vmin;
  /* color: #282c34; */
  color: #243e36;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 500ms ease;
}

.Nav-button:hover{
  background-color: transparent;
  /* background-color: #7ca982; */
  border-style: solid;
  /* border-color: white; */
  border-color: #f1f7ed;
  border-width: .2vmin;
  -webkit-transition : border-width 1000ms, background-color 750ms, border-color 1000ms, border-width 1000ms;
}

.Nav-button:hover .Quote-button-text {
  /* color: white; */
  color: #f1f7ed;
  -webkit-transition : color 500ms;
}

@media only screen and (max-width:1000px) { 
  .Nav-button {
    position: relative;
    right: 0vmin;
    background-color: rgb(246, 243, 242);
    height: 6vmin;
    width: 12vmin;
    border-radius: 2vmin;
    color: #282c34;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .Quote-form-background {
    padding-bottom: 96%;
  }
}

.Nav-text {
  font-family: Merienda;
  font-weight: bolder;
  /* color: white; */
  color:#F1f7ed;
  text-decoration: none;
  position: relative;
  left: 6vmin;
  font-size: 2.5vmin;
}

.Nav-text-seperator {
  font-family: Merienda;
  font-weight: bolder;
  /* color: white; */
  color:#F1f7ed;
  text-decoration: none;
  position: relative;
  left: 6vmin;
  font-size: 2.5vmin;
}

.Nav-text:hover {
  transform: scale(1.25);
  -webkit-transition: transform 500ms;
}

/* First section styling */
.First-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #282c34;
  height: 75vmin;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

.Wrapper-div {
  height: 15vmin;
  background-color: #65686e;
}

.First-section-button {
  background-color: transparent;
  height: 16vmin;
  width: 40vmin;
  border-radius: 3vmin;
  font-family: Merienda;
  font-weight: bolder;
  font-size: 4vmin;
  cursor: pointer;
  position: absolute;
  top: 30vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms ease;
  border: #f1f7ed solid .2vmin;
}

.First-section-button:hover{
  transform: scale(1.1);
  border-style: solid;
  border-width: .5vmin;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -webkit-transition : transform 500ms, background-color 750ms, border-color 500ms, border-width 1000ms;
}

.First-section-button-text {
  text-decoration: none;
  color: #f1f7ed;
  font-family: Merienda;
  font-weight: bolder;
  font-size: 4.5vmin;
  vertical-align: middle;
}

.First-section-text {
  font-family: Merienda;
  font-weight: bolder;
  font-size: 4vmin;
  position: absolute;
  top: -1vmin;
  z-index: 1;
  overflow-wrap: break-word;
  inline-size: 100vmin;
  text-align: center;
  /* color: white; */
  color: #F1f7ed;
  width: 101vmin
}

.First-section-text-2 {
  font-family: Merienda;
  font-weight: bolder;
  font-size: 4vmin;
  position: absolute;
  top: 75vmin;
  z-index: 1;
  overflow-wrap: break-word;
  inline-size: 100vmin;
  text-align: center;
  /* color: white; */
  color: #F1f7ed;
  width: 101vmin;
}

.First-section-footer {
  height: 10vmin;
  background-color: #282c34;
  display: flex;
  place-content: center;
}

.Background-images {
  width: 100%;
  height: 60vmin;
  margin-top: 11vmin;
  position: absolute;
  object-fit: cover;
  display: block;
  -webkit-transition-duration: 600ms;
  transition-duration: 2000ms;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}


/* navigate down button styling */
.Navigate-down-button {
  position: fixed;
  width: 6vmin;
  height: 6vmin;
  border-radius: 50%;
  border: none;
  background-image: url("https://static.thenounproject.com/png/516849-200.png");
  background-size: cover;
  bottom: 2vmin;
  z-index: 1;
  filter: invert(1);
  transition: ease-in-out;
  transition-duration: 400ms;
}

.Navigate-down-button:hover {
  cursor: pointer;
  transform: scale(1.25);
  transition-duration: 400ms;
}
/* 
.Navigate-up-transition {
  transform: scale(1.25) rotate(180deg);
  transition-duration: 400ms;
} */


/* services styling */
.Services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #282c34;
  /* background-color: #002400; */
  /* background-color: #2F0147; */
  /* background-color: #1f0322; */
  /* background-color: #2f0601;
  background-color: #00100B; */
  /* background-color: #7ca982; */
  text-align: center;
  /* color: white; */
  color: #F1f7ed;
  position: relative;
  font-family: Merienda;
}

.Services-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #282c34;
  /* color:#F1f7ed; */
  flex-wrap: wrap;
  padding: 5vmin;
  padding-top: 0;
  padding-bottom: 0;
}

.Services-cell {
  width: 35vmin;
  background-color: #CECCCC;
  /* background-color: #243e36; */
  height: 72vmin;
  margin: 2vmin;
  border-radius: 5vmin;
}

.Services-cell h1{
  font-size: 4.5vmin;
}

.Services-cell li {
  font-size: 2.5vmin;
  text-align: left;
  margin-top: 4vmin;
  font-weight: bold;
  list-style: none;
  position: relative;
}

.Services-cell ul li::before {
  content: '✓';
  margin-left: -3.75vmin;
}


/* Handle */
/* width */
div *::-webkit-scrollbar {
  width: 1vmin;
}

/* Track */
div *::-webkit-scrollbar-track {
  margin-top: 4vmin;
  margin-bottom: 4vmin;
}
 
/* Handle */
div *::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: white;
}

.Services-images {
  width: 12vmin;
  height: 12vmin;
  position: relative;
  filter: brightness(0) saturate(100%) invert(12%) sepia(2%) saturate(5961%) hue-rotate(183deg) brightness(93%) contrast(84%);
}

.Services-image-background {
  height: 15vmin;
  width: 15vmin;
  background-color: #CECCCC;
  border-radius: 50%;
  border-color: #282c34;
  border-width: .5vmin;
  border-style: solid;
  display: flex;
  position: relative;
  left: 10vmin;
  top: 1vmin;
  justify-content: center;
  align-items: center;
}

.Services-title {
  position: relative;
  top: 1vmin;
  font-size: 4.5vmin;
}


/* reviews styling */
.Reviews-wrapper {
  background-color: #282c34;
  /* background-color: #002400; */
  /* background-color: #7ca982; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #282c34;
  flex-wrap: wrap;
  padding: 5vmin;
  position: relative;
  padding-top: 10vmin;
  justify-content: center;
}

.Reviews-cell {
  width: 75vmin;
  background-color: #CECCCC;
  height: 20vmin;
  overflow: auto;
  margin: 2vmin;
  border-radius: 5vmin;
  overscroll-behavior: contain;
}

.Reviews-title {
  position: absolute;
  font-family: Merienda;
  color: #f1f7ed;
  top: 1vmin;
  font-size: 4.5vmin;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

.Reviews-image {
  height: 10vmin;
  width: 10vmin;
  border-radius: 50%;
  position: relative;
  padding: 2vmin;
  border-style: inset;
  border-width: 1vmin;
  top: 2vmin;
  left: 2vmin;
  border-color: #f1f7ed;
}

.Reviews-text {
  position: relative;
  width: 53vmin;
  padding-left: 20vmin;
  top: -17vmin;
  font-family: Merienda;
  height: 0vmin;
}

.Reviews-text p{
  font-size: 2.5vmin;
  margin-top: -4%;
}

.Reviews-text h1{
  font-size: 4vmin;
}


/* About us styling */ 
.About-us {
  display: flex;
  position: relative;
  background-color: #282c34;
  height: 85vmin;
  border: 1px red solid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: merienda;
}

.About-us h1 {
  font-size: 4vmin;
  font-weight: bolder;
  position: absolute;
  color: #f1f7ed;
  top: -1vmin;
}

.About-us-first {
  /* border: 1px blue solid; */
  position: relative;
  display: flex;
  height: 40%;
  width: 90%;
  flex-direction: row;
}

.About-us-second {
  /* border: 1px blue solid; */
  position: relative;
  display: flex;
  height: 40%;
  width: 90%;
  flex-direction: row;
}

.About-us-image-wrapper {
  width: 50%;
  background-color: #65686e;
}

.About-us-text {
  width: 50%;
  background-color: #CECCCC;
  font-size: 2.5vmin;
  color: #282c34;
  
}

.About-us-text p {
  padding-left: 5vmin;
  padding-right: 5vmin;
  padding-top: 2vmin;
  padding-bottom: 2vmin;
  font-weight: bolder;
}


/* quote form styling */
.Quote-form-background {
  background-color: #282c34;
  position: relative;
  width: 100%;
  height: 100%;
  /* bottom: 0vmin; */
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* scroll-behavior: none; */
}

.Quote-form {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #ccc;
  align-items: center;
  width: 100vmin;
  height: 61vmin;
  border-radius: 3vmin;
  font-family: merienda;
}

.Quote-form input[type=submit] {
  position: relative;
  background-color: white;
  color: black;
  height: 10vmin;
  width: 40vmin;
  top: 2vmin;
  border: white;
  border-radius: 2.5vmin;
  cursor: pointer;
  font-size: 3vmin;
  font-family: merienda;
  border-style: solid;
  border-color: #282c34;
  transition: 500ms ease;
}

.Quote-form input[type=submit]:hover {
  background-color: #282c34;
  color: white;
  border-style: solid;
  border-color: white;
  border-width: .5vmin;
  -webkit-transform: scale(1.2);
  -webkit-transition : transform 500ms ease, color 750ms, background-color 750ms, border-color 500ms, border-width 1000ms;
}

.Quote-form input[type=text] {
  position: relative;
  width: 88vmin;
  padding: .8vmin;
  font-size: 2vmin;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  box-sizing:content-box;
  font-family: merienda;
}

.Quote-form #typeOfService {
  position: relative;
  width: 88.5vmin;
  padding: .8vmin;
  font-size: 2vmin;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  box-sizing:content-box;
  font-family: merienda;
}

.Quote-form label {
  margin-top: .5vmin;
  margin-bottom: .5vmin;
  font-size: 2vmin;
  float: left;
  width: 90vmin;
  color: #282c34;
}

.Quote-form textarea {
  position: relative;
  width: 88.5vmin;
  padding: .8vmin;
  font-size: 2vmin;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  box-sizing:content-box;
  height: 10vmin;
  font-family: merienda;
}

.Quote-form-title {
  position: relative;
  color: white;
  font-family: merienda;
  font-size: 4vmin;
}

.Quote-navbar {
  display: flex;
  background-color: #65686e;
  align-items: center;
  height: 10vmin;
  position: fixed;
  width: 100%;
  top: 5vmin;
  z-index: 2;
  max-width: 100%;
  overflow: hidden;
}

.Quote-nav-text {

}

.Quote-navbar #p{
  position: fixed;
  font-family: Merienda;
  font-weight: bolder;
  color: white;
  text-decoration: none;
  font-size: 2.5vmin;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -1vmin;
}

.Quote-navbar #p:hover{
  color: white;
  transform: scale(1.25) translateX(-40%);
  -webkit-transition : color 500ms, transform 500ms;
}

.Quote-footer {
  position: relative;
  width: 100%;
  padding-top: 10vmin;
  /* padding-top: 70%; */
  /* top: 5vmin; */
  /* bottom: -80vmin; */
}

.Quote-form-page {
  height: 100%;
  background-color: #282c34;
}


/* Footer Styling */
.Footer {
  display: flex;
  position: relative;
  background-color:#CECCCC;
  height: 100%;
  color:#282c34;
  justify-content: space-evenly;
}

/* .Footer * {
  border: 1px red solid;
} */

.Footer-info-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  font-family: merienda;
  width: 40vmin;
  max-width: 30%;
}

.Footer-info-wrapper h1{
  font-size: 2vmin;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  border-bottom: .8vmin solid #65686e;
  padding-bottom: 1vmin;
  border-radius: 1vmin;
}

.Info-footer {
  font-size: 1.5vmin;
  font-weight: bolder;
  padding: 1vmin;
  margin-top: -3vmin;
  max-width: auto;
}

.Info-footer a {
  color: inherit;
  text-decoration: inherit;
}

.Info-footer p {
  width: fit-content;
  font-size: 1.5vmin;
  font-family: merienda;
}

.Info-footer p:hover {
  color: white;
  transform: scale(1.05);
  -webkit-transition : color 500ms, transform 500ms;
}

.Reviews-footer {
  font-size: 1.5vmin;
  width: 40vmin;
  max-width: 30%;
}

.Reviews-footer h1{
  text-align: center;
  font-family: merienda;
  font-size: 2vmin;
}

.Reviews-footer h1 {
  border-bottom: .8vmin solid #65686e;
  padding-bottom: 1vmin;
  border-radius: 1vmin;
}

.Hours-footer p {
  width: fit-content;
  font-size: 1.5vmin;
  font-family: merienda;
}

.Reviews-links {
  width: auto;
  display: flex;
  justify-content: space-evenly;
  padding-top: 1.5vmin;
}

.Reviews-links img{
  width: 5.5vmin;
  height: 5.5vmin;
  padding: 1vmin;
}

.Reviews-links img:hover{
  transform: scale(1.2);
  transition: 500ms ease;
}

.Copywright {
  font-size: 2vmin;
  display: flex;
  width: 100%;
  background-color: #65686e;
  color: #f1f7ed;
  justify-content: space-evenly;
  font-family: merienda;
  margin-top: -1vmin;
  align-items: bottom;
  height: 5vmin;
}
